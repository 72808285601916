<template>
  <svg
    class="check_icon"
    :class="{
      is_checked: isChecked
    }"
    focusable="false"
    viewBox="0 0 24 24"
    aria-hidden="true"
    data-testid="CheckCircleRoundedIcon"
  >
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM9.29 16.29 5.7 12.7a.9959.9959 0 0 1 0-1.41c.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.59 7.59c-.38.39-1.02.39-1.41 0z"></path>
  </svg>
</template>

<script>
export default {
  props: {
    isChecked: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.check_icon {
  margin-left: 8px;
  margin-right: -10px;
  color: #ddd;
  width: 20px;
  height: 20px;
  fill: currentColor;
}
.check_icon.is_checked {
  color: rgba(76,175,80,.8);
}
</style>
