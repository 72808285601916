<template>
  <div class="col-sm-12">
    <label class="form-label mt-3 mb-3">{{$t('label.choosePreferContent')}}</label>
    <div class="form-group col-sm-12" style="height: auto;">
      <div class="basic_content_wrap">
        <div
          v-for="item in tagList_1"
          :key="item.name"
          class="content_item"
          @click="addTags(item.name)"
        >
          <PreferIcon :isChecked="userForm.tags.includes(item.name) ? true : false" />
          <div class="prefer_title">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="form-group col-sm-12" style="height: auto;">
      <div class="basic_content_wrap adult_content_wrap">
        <div
          v-for="item in tagList_2"
          :key="item.name"
          class="content_item"
          @click="selectAdultTag(item)"
        >
          <PreferIcon :isChecked="userForm.tags.includes(item.name) ? true : false" />
          <div class="prefer_title">{{ item.name }}</div>
        </div>
      </div>
    </div>

    <div v-show="showWarnModal" class="tips_modal_wrap">
      <div class="tips_modal">
        <div class="tips_header">
          <svg class="warn_icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="ReportProblemTwoToneIcon"><path d="M12 5.99 4.47 19h15.06L12 5.99zM13 18h-2v-2h2v2zm-2-4v-4h2v4h-2z" opacity=".3"></path><path d="M12 2 1 21h22L12 2zm0 3.99L19.53 19H4.47L12 5.99zM11 16h2v2h-2zm0-6h2v4h-2z"></path></svg>
          <div>{{ $t('label.RatedContent') }}</div>
        </div>
        <div class="tips_body">
          <div>{{ $t('label.18_disabled_1_info') }}</div>
          <div>{{ $t('label.18_disabled_2_info') }}</div>
        </div>
        <div class="tips_footer">
          <div @click="showWarnModal=false;">{{ $t('button.cancel') }}</div>
          <div @click="addTags(curSelectItem);showWarnModal=false;">{{ $t('button.confirm') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PreferIcon from './CheckPreferIcon'
import { getTagList } from '@/api/settings'
export default {
  props: {
    userForm: {
      type: Object,
      default: () => ({})
    },
    addTags: {
      type: Function,
      default: () => 1
    }
  },
  components: {
    PreferIcon
  },
  data() {
    return {
      tagList_1: [],
      tagList_2: [],
      showWarnModal: false,
      curSelectItem: ''
    }
  },
  mounted() {
    this.getTagListFunc()
  },
  methods: {
    getTagListFunc() {
      getTagList()
        .then(res => {
          this.tagList_1 = res.tags_1
          this.tagList_2 = res.tags_2
          if (this.$route.name === 'auth1.sign-up') {
            const obj = {
              notAdult: this.tagList_1,
              adult: this.tagList_2
            }
            this.$emit('getTagList', obj)
          }
        })
    },
    selectAdultTag(item) {
      this.curSelectItem = item.name;
      if (this.userForm.tags.includes(item.name)) {
        this.addTags(this.curSelectItem)
        return
      }

      this.showWarnModal=true
    }
  }
}
</script>

<style scoped>
.basic_content_wrap {
  padding: 8px 8px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 16px;
  border: 3px solid rgba(153,205,234,.4);
  margin-bottom: 8px;
}
.content_item {
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;

  position: relative;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 16px;
  white-space: nowrap;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  outline: 0px;
  box-sizing: border-box;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  border: 1px solid rgb(189, 189, 189);
  background-color: transparent;
}
.prefer_title {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;

}

.adult_content_wrap {
  border: 3px solid rgba(255,205,234,.4);
}

.tips_modal_wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 1001;
}
.tips_modal {
  width: 320px;
  max-width: 320px;
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  max-height: calc(100% - 64px);
  margin: 200px auto;
}
.tips_header {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  padding: 16px 24px;
  display: flex;
  align-items: center;
}
.warn_icon {
  width: 24px;
  height: 24px;
  margin-right: 4px;
  fill: currentColor;
}
.tips_body {
  padding: 0 24px 14px;
  font-size: 14px;
}
.tips_footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 8px 8px;
  cursor: pointer;
}
.tips_footer > div {
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(63, 81, 181);
  min-width: 64px;
  padding: 6px 8px;
  letter-spacing: 1px;
  cursor: pointer;
  text-align: center;
  border-radius: 16px;
}
.tips_footer > div:hover {
  text-transform: none;
  text-decoration: none;
  background-color: rgba(63, 81, 181, 0.04);
}
</style>
